.carousel {
  margin-top: 72px;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  @media screen and (max-width: 639px) {
    margin-top: 48px;
  }
}

.carousel-items {
  position: relative;
  width: 100%;
  height: 272px;
  @media screen and (max-width: 639px) {
    height: 200px;
  }
}

.carousel-grad {
  background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 1;
}

.carousel-item {
  position: relative;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  width: 272px;
  height: 272px;
  overflow: hidden;
  @media screen and (max-width: 639px) {
    width: 200px;
    height: 200px;
  }
}

.carousel-item-bg {
  position: absolute;
  background: conic-gradient(from 180deg at 50% 50%, #F1B0E6 -55.8deg, #FAF4B7 52.2deg, #B3E5BE 140.4deg, #B5DEFF 228.6deg, #F1B0E6 304.2deg, #FAF4B7 412.2deg);
  box-shadow: 0px 0px 4px 0px #00000038 inset;
}

.carousel-item-content {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  background: white;
  border-radius: 50%;
  box-shadow: 0px 0px 8px 0px #0000001F,0px 0px 4px 0px #0000003D;
}

.carousel-bottom {
  display: flex;
  height: 72px;
  justify-content: space-between;
  align-items: center;
  margin: 36px 32px 0;
  gap: 16px;
  @media screen and (max-width: 639px) {
    margin: 24px 16px 0;
    gap: 8px;
  }
}

.carousel-button.prev {
  left: 10px;
}

.carousel-button.next {
  right: 10px;
}