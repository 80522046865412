@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Schoolbell&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-blur {
  background: linear-gradient(199.97deg, rgba(72, 1, 188, 0.21) 19.68%, rgba(206, 175, 255, 0.63) 45.08%, rgba(255, 249, 200, 0.028) 109.96%, rgba(204, 241, 255, 0.7) 109.97%, rgba(61, 63, 64, 0.7) 109.98%);
}

.card-shadow-outer {
  box-shadow: 0px -2px 4px 0px #0000001A inset,0px 4px 4px 0px #0000004D,0px 8px 12px 6px #00000026;
}
.card-shadow-inner {
  border-bottom: 4px solid;
  border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 29.67%, rgba(255, 255, 255, 0) 64.17%, rgba(255, 255, 255, 0.5) 95.67%);
  box-shadow: 0px 4px 8px 0px #00000014, 0px 12px 0px 0px #E3E5E733;
}
.arrow-button-shadow {
  border: 3px solid;
  border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 29.67%, rgba(255, 255, 255, 0) 64.17%, rgba(255, 255, 255, 0.1) 95.67%);
  box-shadow: 0px -2px 2px 0px #0000001A inset,0px 0px 40px 0px #C0C2C542,0px 2px 6px 0px #00000014,0px 12px 16px 0px #C0C2C566,0px 2px 8px 0px #00000040,0px -2px 4px 0px #0000001A inset;
}
.draw-button-shadow {
  box-shadow: 0px -2px 4px 0px #0000001A inset,0px 12px 16px 0px #C0C2C566,0px 2px 8px 0px #00000040;
}
.draw-button-gradient {
  box-shadow: 0px 0px 4px 0px #00000038 inset,0px 0px 7.77px 0px #00000038 inset;
  background: conic-gradient(from 180deg at 50% 50%, #F1B0E6 -55.8deg, #FAF4B7 52.2deg, #B3E5BE 140.4deg, #B5DEFF 228.6deg, #F1B0E6 304.2deg, #FAF4B7 412.2deg);
}
.draw-button-disabled {
  box-shadow: 0px 0px 4px 0px #00000038 inset;
  background-color: #00000038;
}
.card-button {
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 12px 0px #989B9C1A,0px 2px 4px 0px #0000001A,0px 2px 1px 0px #00000003,0px -2px 4px 0px #0000001A inset;
  background: #F9F9FC;
  border-radius: 100%;
  border: 1px solid #E3E5E7;
  @media screen and (max-width: 639px) {
    width: 48px;
    height: 48px;
  }
}
.bubble-shadow {
  box-shadow: 0px -4px 8px 0px #5C428933 inset,0px 11px 8px 0px #FFFFFF4D inset;
}
.button-shadow {
  box-shadow: 0px -4px 2px 0px #00000033 inset,0px 4px 2px 0px #FFFFFF40 inset,0px 0px 20px 0px #989B9C1A,0px 2px 12px 0px #0000001A,0px 1px 3px 0px #00000014;
}
.button-shadow-pressed {
  box-shadow: 0px 0px 11.37px 0px #0000000A,0px 1.89px 11.37px 0px #0000001A,0px 0.95px 1.89px 0px #00000014,0px -1.89px 0.95px 0px #0000004D inset,0px 1.89px 1.89px 0px #FFFFFF73 inset;
}
